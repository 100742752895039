import React from "react"

import styled from "@emotion/styled"
import { breakpoints, colours } from "design-kit"

const HeroSection = styled.section<{ backgroundImg?: string }>`
  width: 100%;
  min-height: 180px;
  padding: 24px 24px;
  display: flex;
  align-items: center;
  background-color: ${colours.offBlack};
  box-sizing: border-box;

  ${props => {
    if (props.backgroundImg) {
      return `
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-image: url(${props.backgroundImg});
      `
    }
  }}

  ${breakpoints.tablet`
    min-height: 480px;
    padding: 96px 0;
    justify-content: center;
  `}
`

interface Props {
  backgroundImg?: string
  className?: string
}

const View: React.FunctionComponent<Props> = ({
  backgroundImg,
  className,
  children,
}) => (
  <HeroSection backgroundImg={backgroundImg} className={className}>
    {children}
  </HeroSection>
)

export default View
