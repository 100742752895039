import React from "react"

import styled from "@emotion/styled"

import {
  typographyStyles,
  colours,
  mixins,
  BodySmall,
  HabitoLogo,
  breakpoints,
  MetaText,
  vertical,
} from "design-kit"

import { routes } from "../Navbar/Shared"

const Footer = styled.footer`
  padding: 48px 0;
  border-top: 1px solid ${colours.greyScale.grey25};
  background-color: ${colours.offBlack};
  box-sizing: border-box;

  ${breakpoints.tablet`
    height: 418px;
  `}
`

const Content = styled.div`
  max-width: 1336px;
  height: 100%;
  margin: auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  ${breakpoints.tablet`
    padding: 0 48px;
  `}

  ${breakpoints.desktop`
    padding: 0 96px;
  `}
`

const Nav = styled.nav`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  ${breakpoints.tablet`
    align-items: center;
    flex-direction: row;
  `}
`

const NavItem = styled.a`
  ${typographyStyles.bodyBold};
  padding: 4px;
  margin-right: auto;
  margin-bottom: 16px;
  border-radius: 4px;
  text-decoration: none;
  color: ${colours.white};

  &:focus {
    ${mixins.focused};
  }

  &:hover {
    color: ${colours.callout.main};
  }

  ${breakpoints.tablet`
    margin-right: 24px;
    margin-bottom: 0;
  `}
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
`

const LogoContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`

const LogoAnchor = styled.a`
  padding: 4px;
  border-radius: 4px;

  &:focus {
    ${mixins.focused};
  }
`

const Address = styled(BodySmall)`
  margin-bottom: ${vertical.xs};
  color: ${colours.white};
`

const InfoText = styled(MetaText)`
  color: ${colours.white};
`

const TelAnchor = styled.a`
  color: ${colours.white};
  text-decoration: none;

  &:focus,
  &:hover {
    color: ${colours.callout.main};
  }
`

const View: React.FunctionComponent = () => (
  <Footer>
    <Content>
      <Nav>
        <NavItem href={routes.mission}>Mission</NavItem>
        <NavItem href={routes.values}>Values</NavItem>
        <NavItem href={routes.howWeWork}>How we work</NavItem>
        <NavItem href={routes.youAndHabito}>You and Habito</NavItem>
      </Nav>

      <Info>
        <LogoContainer>
          <LogoAnchor href="/">
            <HabitoLogo height={40} color={colours.white} />
          </LogoAnchor>
        </LogoContainer>

        <Address>
          Habito, WeWork, Moor Place, 1 Fore Street Avenue, London, EC2Y 9DT, GB
        </Address>

        <InfoText>
          Hey Habito Ltd. is registered in England and Wales (
          <TelAnchor href="tel:09384953">09384953</TelAnchor>) with address C/O
          4th Floor Phoenix House, 1 Station Hill, Reading, Berkshire, RG1 1NB,
          is authorised and regulated by the Financial Conduct Authority and is
          on the Financial Services Register (714187).
        </InfoText>
      </Info>
    </Content>
  </Footer>
)

export default View
