import React from "react"

import styled from "@emotion/styled"
import { breakpoints } from "design-kit"

const ImageSection = styled.section<{ variant: Variant }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${props => {
    switch (props.variant) {
      case "normal":
        return breakpoints.tablet`
          min-height: 650px;
          flex-direction: row;
        `
      case "reverse":
        return breakpoints.tablet`
          min-height: 650px;
          flex-direction: row-reverse;
        `
    }
  }}
`

const LeftSide = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  display: flex;
  padding: 24px;
  box-sizing: border-box;
  background-color: ${props => `${props.backgroundColor || "transparent"}`};

  ${breakpoints.tablet`
    width: 50%;
  `}
`

const LeftSideInner = styled.div<{ variant: Variant }>`
  margin: auto;
  box-sizing: border-box;

  ${breakpoints.tablet`
    max-width: 468px;
  `}

  ${props => {
    switch (props.variant) {
      case "normal":
        return breakpoints.desktop`
          margin-right: 80px;
        `
      case "reverse":
        return breakpoints.desktop`
          margin-left: 80px;
        `
    }
  }}
`

const RightSide = styled.div<{ backgroundImage: string }>`
  width: 100%;
  min-height: 320px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(${props => props.backgroundImage});

  ${breakpoints.tablet`
    width: 50%;
  `}
`

type Variant = "normal" | "reverse"

interface Props {
  image: string
  variant: Variant
  backgroundColor?: string
  className?: string
}

const View: React.FunctionComponent<Props> = ({
  image,
  variant,
  backgroundColor,
  className,
  children,
}) => (
  <ImageSection className={className} variant={variant}>
    <LeftSide backgroundColor={backgroundColor}>
      <LeftSideInner variant={variant}>{children}</LeftSideInner>
    </LeftSide>

    <RightSide backgroundImage={image} />
  </ImageSection>
)

export default View
