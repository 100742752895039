import { WindowLocation } from "@reach/router"

export const routes = {
  mission: "/culture",
  values: "/culture/values",
  howWeWork: "/culture/how-we-work",
  youAndHabito: "/culture/you-and-habito",
}

export const isActive = (
  { pathname }: WindowLocation,
  path: string
): boolean => {
  if (pathname.endsWith("/")) {
    return pathname === `${path}/`
  } else {
    return pathname === path
  }
}
