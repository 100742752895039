import styled from "@emotion/styled"
import { WindowLocation } from "@reach/router"
import { Body, breakpoints, colours, typographyStyles } from "design-kit"

export const colors = {
  aquamarine: "#94F4D8",
  gray: "#F5F5F6",
  yellow: "#F0FF97",
}

export interface Props {
  location: WindowLocation
}

export const Main = styled.main`
  display: flex;
  flex-direction: column;
`

export const HeroHeading = styled.h1`
  ${typographyStyles.headline2};
  color: ${colours.white};

  ${breakpoints.tablet`
    width: 55%;
    font-size: 80px;
    line-height: 1;
  `}

  ${breakpoints.desktop`
    font-size: 120px;
  `}
`

export const Paragraph = styled(Body)`
  margin: 0;
  margin-bottom: 24px;
  color: ${colours.offBlack};
`

export const SectionHeading = styled.h2`
  ${typographyStyles.headline3};
  margin-bottom: 16px;
  font-weight: bold;
  color: ${colours.offBlack};
`
