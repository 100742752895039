import React from "react"
import styled from "@emotion/styled"

import { HabitoLogo, mixins, breakpoints } from "design-kit"

import DesktopNav from "./Desktop"
import MobileNav from "./Mobile"
import { Props } from ".."

const Header = styled.header`
  padding: 16px;
  box-sizing: border-box;

  ${breakpoints.tablet`
    max-height: 96px;
    padding: 24px 32px;
  `}

  ${breakpoints.desktop`
    padding: 24px 96px;
  `}
`

const Content = styled.div`
  max-width: 1336px;
  margin: auto;
  display: flex;
  align-items: center;
`

const Logo: React.FC = () => {
  const AnchorTag = styled.a`
    padding: 4px;
    border-radius: 4px;
    &:focus {
      ${mixins.focused};
    }

    ${breakpoints.tablet`
      > svg {
        height: 32px;
      }
    `}

    ${breakpoints.desktop`
      > svg {
        height: 40px;
      }
    `}
  `

  return (
    <AnchorTag href="/">
      <HabitoLogo />
    </AnchorTag>
  )
}

const View: React.FunctionComponent<Props> = ({ location }) => (
  <Header>
    <Content>
      <Logo />

      <DesktopNav location={location} />
      <MobileNav location={location} />
    </Content>
  </Header>
)

export default View
