import React from "react"
import styled from "@emotion/styled"

import {
  breakpoints,
  MenuIcon,
  colours,
  mixins,
  CloseIcon,
  typographyStyles,
  iconSize,
} from "design-kit"

import { isActive, routes } from "./Shared"
import { Props } from ".."

const MobileNav = styled.nav`
  display: flex;
  margin-left: auto;

  ${breakpoints.tablet`
    display: none
  `}
`

const ToggleBtn = styled.button`
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;

  &:focus {
    ${mixins.focused};
  }
`

const Overlay = styled.nav`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 72px; // Header height.
  left: 0;
  background-color: rgba(22, 22, 61, 0.6);
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  background-color: ${colours.white};
`

const MenuItem = styled.a<{ isActive: boolean }>`
  ${typographyStyles.body};
  height: 48px;
  padding-left: 16px;
  line-height: 48px;
  text-decoration: none;
  border-top: 1px solid ${colours.greyScale.grey50};

  ${props =>
    props.isActive
      ? `
    color: ${colours.white};
    background-color: ${colours.action.main};
  `
      : `
    color: ${colours.offBlack};
    background-color: ${colours.white};
  `}
`

const View: React.FunctionComponent<Props> = ({ location }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [isOpen])

  return (
    <React.Fragment>
      <MobileNav>
        <ToggleBtn onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <CloseIcon color={colours.offBlack} />
          ) : (
            <MenuIcon
              width={iconSize.m}
              height={iconSize.m}
              color={colours.offBlack}
            />
          )}
        </ToggleBtn>
      </MobileNav>

      {isOpen && (
        <Overlay onClick={() => setIsOpen(false)}>
          <Nav onClick={evt => evt.stopPropagation()}>
            <MenuItem
              href={routes.mission}
              isActive={isActive(location, routes.mission)}
            >
              Mission
            </MenuItem>

            <MenuItem
              href={routes.values}
              isActive={isActive(location, routes.values)}
            >
              Values
            </MenuItem>

            <MenuItem
              href={routes.howWeWork}
              isActive={isActive(location, routes.howWeWork)}
            >
              How we work
            </MenuItem>

            <MenuItem
              href={routes.youAndHabito}
              isActive={isActive(location, routes.youAndHabito)}
            >
              You and Habito
            </MenuItem>
          </Nav>
        </Overlay>
      )}
    </React.Fragment>
  )
}

export default View
