import React from "react"
import styled from "@emotion/styled"

import { breakpoints, typographyStyles, colours } from "design-kit"

import { isActive, routes } from "./Shared"
import { Props } from ".."

const DesktopNav = styled.nav`
  display: none;
  margin-left: auto;

  ${breakpoints.tablet`
    display: flex;
    align-items: center;
  `}
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
`

const ListItem = styled.li`
  margin-left: 24px;
`

const NavItem = styled.a<{ isActive: boolean }>`
  ${typographyStyles.body};
  display: flex;
  justify-content: center;
  position: relative;
  text-decoration: none;
  color: ${colours.offBlack};

  &:after {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    top: 32px;
    transition: 0.3s;
    background-color: ${colours.action.main};
  }

  &:hover {
    color: ${colours.action.main};

    &:after {
      width: 100%;
    }
  }

  ${props =>
    props.isActive
      ? `
    color: ${colours.action.main};

    &:after {
      width: 100%;
    }
  `
      : ""};
`

const View: React.FunctionComponent<Props> = ({ location }) => (
  <DesktopNav>
    <List>
      <ListItem>
        <NavItem
          href={routes.mission}
          isActive={isActive(location, routes.mission)}
        >
          Mission
        </NavItem>
      </ListItem>

      <ListItem>
        <NavItem
          href={routes.values}
          isActive={isActive(location, routes.values)}
        >
          Values
        </NavItem>
      </ListItem>

      <ListItem>
        <NavItem
          href={routes.howWeWork}
          isActive={isActive(location, routes.howWeWork)}
        >
          How we work
        </NavItem>
      </ListItem>

      <ListItem>
        <NavItem
          href={routes.youAndHabito}
          isActive={isActive(location, routes.youAndHabito)}
        >
          You and Habito
        </NavItem>
      </ListItem>
    </List>
  </DesktopNav>
)

export default View
